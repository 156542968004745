@import "~@jutro/theme/assets/sass/helpers";
.pageContainer {
	margin-top: var(--GW-SPACING-8);
}
.policySectionStyle {
	padding-bottom: var(--GW-SPACING-4);
	border-bottom: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-BORDER-COLOR);
	margin-bottom: var(--GW-SPACING-8);
}
.coverageTitle {
	--gw-policy-section-padding: var(--GW-SPACING-4);
	padding: var(--gw-policy-section-padding);
}
.coverageDetailsContainer {
	margin-top: var(--GW-SPACING-4);
	background-color: var(--GW-COLOR-DARK-CONTRAST);
}


.toggleLocationInfo {
    text-decoration: none;
    text-align: right;
    justify-content: right;
    color: var(--GW-TEXT-COLOR-1);

    &:hover {
		color: var(--GW-TEXT-COLOR-1-HOVER);
		text-decoration: none;
    }
}
