:root {
    --gw--map-label-margin-bottom: 10px;
}

.detailsContainer {
    .label-backgroundcolor {
        background-color: var(--GW-BACKGROUND-COLOR);
        font-size: var(--GW-FONT-SIZE-BODY);
        margin-bottom: var(--gw--map-label-margin-bottom);
    }
}

.propertyDamage {
    align-items: baseline;
    [class*= "controls"] {
        align-self: flex-start;
    }
}
