@import "~@jutro/theme/assets/sass/helpers";

.imageContainer {
  grid-template-areas: "label field";
}

.vehicleImage {
  --gw-vehicle-image-min-width: 360px;
  --gw-vehicle-image-width: 360px;
  --gw-vehicle-image-height: 270px;

  @include gw-breakpoint-down(phone) {
    --gw-vehicle-image-min-width: 100%;
  }

  min-width: var(--gw-vehicle-image-min-width);
  width: var(--gw-vehicle-image-width);
  height: var(--gw-vehicle-image-height);
  padding-bottom: var(--GW-SPACING-5);
}

.imageInfoContainer {
  grid-area: field;
}

.vinInfoText {
  margin-top: var(--GW-SPACING-6);
}