@import "~@jutro/theme/assets/sass/helpers";

.sideBar {
    @include gw-breakpoint-only(phone) {
        display: none;
    }
}

.gwWizard {
    --gw-wizard-min-height: 600px;
    display: inline-block;
    height: 100%;
    width: 100%;
    min-height: var(--gw-wizard-min-height);
    text-align: center;
    transform: translateX(0);
    transition: 0.5s all cubic-bezier(0.32, 1.25, 0.375, 1.15);
}

.wizardSidebar{
    --wizardSidebar-width: 23%;
    --wizardSidebar-margin-right: .4%;

    display: inline-block;
    width: var(--wizardSidebar-width);
    vertical-align: top;
    margin-right: var(--wizardSidebar-margin-right);
    text-align: center;
}

.wizardMain {
    --wizardMain-width: 75%;

    text-align: left;
    display: inline-block;
    width: var(--wizardMain-width);
    vertical-align: top;
}

.wizardSidebarSteps{

    text-align: left;
    background-color: var(--GW-BACKGROUND-COLOR);
    border-radius: var(--GW-SPACING-1);
}

.gwsteps {
    --gwsteps-line-height: var(--GW-LINE-HEIGHT-H5);

    line-height: var(--gwsteps-line-height);
}

.ulStyle {
    list-style: none;
    text-align: left;
    margin: var(--GW-SPACING-8);
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.wizardWrapper{
    padding-top: var(--GW-SPACING-4);
    padding-bottom: var(--GW-SPACING-1);
    text-align: left;
    background-color: var(--GW-BACKGROUND-COLOR);
    border-radius: var(--GW-SPACING-1);
    box-shadow: var(--GW-SHADOW-2);
}

.searchTopicInputField {
    margin-top: var(--GW-SPACING-4);
}

.gwFaqSection {
    padding-top: var(--GW-SPACING-4);
}

.faqSectionContainer {
    padding-bottom: var(--GW-NEXT-SPACING-12);

  .faqnoresult {
    text-align: center;
    padding-top: var(--GW-NEXT-SPACING-10);

  }
}

.wizardLi {
    display: block;
    padding: var(--GW-SPACING-3) var(--GW-SPACING-5) var(--GW-SPACING-3);
    margin-bottom: var(--GW-SPACING-1);
    position: relative;
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    cursor: pointer;

    a {
        cursor: pointer;
        color: var(--GW-FOCUS-COLOR);
        text-decoration: none;
    }

    a:hover {
        color: var(--GW-LINK-COLOR-HOVER);
        text-decoration: underline;
    }
}

.wizardLiDisabled {
    cursor: default;

    a {
        color: var(--GW-SEPARATOR-COLOR);
        text-decoration: none;
        pointer-events: none;
    }
}

.wizardLink {
    --wizardLink-width: 100%;

    text-decoration:none;
    width:var(--wizardLink-width);
}

.sideBarTitle{
    --sideBarTitle-margin-top: 15px;
    font-size: var(--GW-FONT-SIZE-H5);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    line-height: var(--GW-LINE-HEIGHT-H4);
    padding: 0 var(--GW-NEXT-LAYOUT-3);
    margin-bottom: var(--GW-NEXT-LAYOUT-3);
    margin-top: var(--GW-NEXT-SPACING-5);
    border-bottom: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-SEPARATOR-COLOR);
    display: block;
}
.gwSectionInactive{
        pointer-events:none;
        color: var(--GW-SEPARATOR-COLOR) !important;
}
.gwSectionActive{background-color:var(--GW-COLOR-INFO-CONTRAST)}

.gwPageSectionTitle {
    padding-bottom: var(--GW-SPACING-2);
    margin-left: var(--GW-SPACING-5);
    margin-bottom: var(--GW-SPACING-8);
}

.searchTopic {
    padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);
    margin: var(--GW-SPACING-6)
}

.headingColor{
    color:#0033ab;
    font-size: var(--GW-FONT-SIZE-H2);
}
.sideHeading{
    color: rgb(86, 86, 221);
    font-size: larger;
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
}

.label{
    margin-bottom: -23px;
}

.plabel{
    margin-bottom: -3px;
}
.cancellabel{
    margin-bottom: -10px;
}
.labelPayment{
    margin-bottom: -23px;
    margin-top: -23px;
}

.labelEnrol{
    margin-bottom: 0px;
    margin-top: 0px;
}

.labelNew{
    margin-bottom: -7px;
    margin-top: -12px;
}

labelstatic{
    margin-bottom: -7px;
    margin-top: -12px;
}
.paraLabel{
    margin-bottom: 3px
}

.labelAgentOne{
    margin-bottom: 5px;
}

.labelAgentTwo{
    margin-bottom: 17px;
}
.nlcText{
    margin-bottom: 5px;
    font-weight: 700;
    margin-top: 31px
}
.nlcTextOne{
    margin-bottom: 5px;
    font-weight: 700;
    margin-top:8px
}
.nlcTextTwo{
    margin-bottom: 5px;
    font-weight: 700;
    margin-top:8px
}
.nlcTextPay{
    margin-bottom: 5px;
    font-weight: 700;
    margin-top:8px 
}

.nlcTextTwo1{
    margin-bottom: 23px;
    margin-top:17px
}

.nlcTextVie{
    margin-bottom: 20px;
}