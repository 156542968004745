.signUpOtpContainer {

  > * {
      margin-bottom: var(--GW-SPACING-6);
  }
  .heading {
    font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    font-size: var(--GW-FONT-SIZE-H2);
    margin-bottom: 2%;
    text-align: center;
  }

}

.heading {
  font-weight: var(--GW-FONT-WEIGHT-REGULAR);
  font-size: var(--GW-FONT-SIZE-H2);
  margin-bottom: 2%;
  text-align: center;
}

.nextButton{
  margin-top:10%
}

.actionContainer{
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;
}

.signupActionContainer{
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 20px;
}

.tncContainer{
  flex-direction: row;
  display: flex;
  align-items: center;
}

.otpSuccessText{
  font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
  padding-bottom: var(--GW-SPACING-3);
  color: #222
}

.otpInfoText{
  padding-top: var(--GW-SPACING-3);
  font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
  color: #222
}

