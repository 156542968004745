
.createNote {
    .gwBox {
        margin: 0 auto var(--GW-SPACING-1);
        padding: 0;
        line-height: var(--GW-LINE-HEIGHT-H5);
    }

    .gwPage {
        --gw-page-padding: 0 50px 40px 50px;
        padding: var(--gw-page-padding);
    }

    .gwLoaderWrapperLarge {
        --gw-loader-wrapper-large-min-width: 1.2em;
        --gw-loader-wrapper-large-max-width: 90%;
        min-width: var(--gw-loader-wrapper-large-min-width);
        max-width: var(--gw-loader-wrapper-large-max-width);
    }

    .gwInlineBackNav {
        position: relative;
    }

    .gwButtonBack {
        --gw-button-back-left: -45px;
        --gw-button-back-top: 45%;
        position: absolute;
        left: var(--gw-button-back-left);
        top: var(--gw-button-back-top);
        cursor: pointer;
    }

    .backToHomeImg {
        transform: translateY(-45%);
    }

    .buttonContainer {
        margin-top: var(--GW-SPACING-5);
    }

}