:root {
--gw_error_message_margin_bottom: 40px;
--gw_error_message_padding: 16px;
--gw_error_message_border-radius: 2px;
--gw_error_message_min_height: 38px;
--gw_alert_icon_padding_right: 16px;
--gw_alert_icon_margin_top: 10px;
--gw_alert_content_padding_top: 5px;
--gw_verify_contactinfo_para_margin_bottom: 10px;
}

.error_message {
    background-color: var(--GW-BACKGROUND-COLOR-BODY);
    border-color: var(--GW-BACKGROUND-COLOR-BODY);
    display: flex;
    flex-direction: row;
    margin-bottom: var(--gw_error_message_margin_bottom);
    padding: var(--gw_error_message_padding);
    border: 1px solid transparent;
    border-radius: var(--gw_error_message_border-radius);
    min-height: var(--gw_error_message_min_height);
    font-size: var(--GW-FONT-SIZE-BODY);
}

.gw_alert_icon {
    flex: 0 auto;
    font-size: var(--GW-FONT-SIZE-H3);
    padding-right: var(--gw_alert_icon_padding_right);
    color: var(--GW-COLOR-ERROR);
    margin-top: var(--gw_alert_icon_margin_top);
}

.gw_alert_content {
    flex: 1 auto;
    width: 100%;
    padding-top: var(--gw_alert_content_padding_top);
}

.gw_verify_contactinfo_para {
    margin-bottom: var(--gw_verify_contactinfo_para_margin_bottom);
}
