@import "~@jutro/theme/assets/sass/helpers";

:root {
    --gw-policy-change-info-icon-size: 1.5rem;
    --gw-policyperiod-date-value-width: 183px;
    --gw-policyperiod-range-width: 36px;
}

.gwPolicyChangeMessageContainer {
    background-color: var(--GW-HIGHLIGHT-COLOR-1);
    border-color: var(--GW-HIGHLIGHT-COLOR-2);
    margin-top: calc(4 * var(--GW-SPACING-4));
    margin-bottom: calc(3 * var(--GW-SPACING-4));
}
.premiumValue {
    display: table-cell;
    & > span {
        display: table-cell;
    }
}
.tabMargin {
    @include gw-breakpoint-up(tablet) {
        margin: var(--GW-SPACING-5);
    }
}
.gwPolicyChangeInfoIcon {
    color: var(--GW-HIGHLIGHT-COLOR-2);
    background-color: var(--GW-LINK-COLOR-HOVER);
    height: var(--gw-policy-change-info-icon-size);
    width: var(--gw-policy-change-info-icon-size);
    border-radius: calc(2 * var(--GW-SPACING-4));
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--GW-SPACING-4);
    margin-left:var(--GW-SPACING-10);
}
.gwPollicyChangeMessageDetails {
    padding: var(--GW-SPACING-4);
}
.gwPolicyChangeValue {
    font-size: var(--GW-FONT-SIZE-BODY);
    [class*="CurrencyField"] {
        float: right;
    }
}
.changeInCost {
    [class*="FieldComponent"] {
        float: right;
    }
    [class*="CurrencyField"] {
        font-size: var(--GW-FONT-SIZE-BODY);
    }
}
.gwPolicyChangeLabel {
    color: var(--GW-BRAND-COLOR-2);
}
.gwPolicyChangePremiumValue {
    --field-component-control-readonly-font-size: var(--GW-FONT-SIZE-H2);
    font-size: var(--GW-FONT-SIZE-H2);
    text-align: right;
}
.iconClass {
    border-radius: var(--gw-quoteContainer-icon-border-radius);
    width: var(--gw-quoteContainer-icon-border-width);
    height: var(--gw-quoteContainer-icon-border-height);
    background-color: var(--GW-BACKGROUND-COLOR-BODY);
    display: flex;
    justify-content: center;
    align-items: center;
}
.gwPolicyChangeInfoMessage {
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    line-height: var(--GW-LINE-HEIGHT-H5);
    color: var(--GW-BRAND-COLOR-2);
    background-color: transparent;
    padding: 0;
}

.policyPeriodStyle {
    white-space: nowrap;
    width: var(--gw-policyperiod-range-width);
}

.policyPeriodDateField {
    width: var(--gw-policyperiod-date-value-width);
}
