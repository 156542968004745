:root {
    --gw-box-margin: 0 auto 5px 0px;
    --gw-btn-back-left: -75px;
    --gw-btn-back-top: 50%;
    --gw-status-icon-padding: 5px;
    --gw-status-icon-height: 20px;
    --gw-status-icon-border-radius: 1em;
    --gw-service-grid-border-bottom: 1px;
    --gw-payment-grid-border-bottom: 1px;
    }
    
    
    
    .claimDetailsMainContainer{
    display: block ;
    }
    .claimDetails {
    
    
    
    .claimDetailsGrid {
    margin: var(--GW-SPACING-5) 0;
    }
    
    
    
    .gwBackToClaim {
    justify-self: left;
    text-decoration: none;
    }
    
    
    
    .gwPrimaryButton {
    margin-top: var(--GW-SPACING-3);
    }
    
    
    
    .labelHeader {
    --gw-claims-header-padding-top: 30px;
    --gw-claims-header-padding-bottom: 16px;
    --gw-claims-header-margin-bottom: 30px;
    --gw-claims-header-border-bottom: 2px;
    padding-bottom: var(--gw-claims-header-padding-bottom);
    border-bottom: var(--gw-claims-header-border-bottom) solid var(--GW-BORDER-COLOR);
    padding-top: var(--gw-claims-header-padding-top);
    margin-bottom: var(--gw-claims-header-margin-bottom);
    }
    
    
    
    .documentIcon {
    cursor: pointer;
    }
    
    
    
    .headerText {
    text-transform: uppercase;
    }
    
    
    
    .claimDetailsContainer{
    padding: var(--GW-SPACING-3) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);
    }
    
    
    
    .claimDetailsIcon {
    background: var(--GW-COLOR-SUCCESS);
    color: var(--GW-BACKGROUND-COLOR);
    font-size: var(--GW-FONT-SIZE-H4);
    border-radius:var(--GW-SPACING-10);
    margin-right: var(--GW-SPACING-2);
    padding: var(--GW-SPACING-2);
    width:var(--GW-SPACING-10);
    height:var(--GW-SPACING-10);
    }
    
    
    
    .claimDetailsNumberContainer{
    .claimDetailNumber{
    margin-bottom: 0;
    }
    }
    
    
    
    .claimServicesContainer{
    .services-grid{
    margin-top: var(--GW-SPACING-4);
    padding-bottom: var(--GW-SPACING-4);
    border-bottom: var(--gw-service-grid-border-bottom) solid var(--GW-BORDER-COLOR);
    }
    .labelBackgroundStyle {
    --info-label-padding-x: 0;
    background-color: transparent;
    font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    }
    .noClaimServicesDetails{
    font-size: var(--GW-FONT-SIZE-BODY);
    }
    .gwInfoIcon{
    font-size: var(--GW-FONT-SIZE-BODY);
    }
    .statusIcon{
    background: var(--GW-BRAND-COLOR-1);
    padding: var(--gw-status-icon-padding);
    border-radius: var(--gw-status-icon-border-radius);
    color: var(--GW-TEXT-COLOR-3);
    font-size: var(--GW-FONT-SIZE-SUBTEXT);
    height: var(--gw-status-icon-height);
    }
    .gwStatusLabel{
    font-size: var(--GW-FONT-SIZE-H5);
    font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    line-height: var(--GW-LINE-HEIGHT-H5);
    color: var(--GW-TEXT-COLOR-1);
    }
    
    
    
    .vendorLinkContainer {
    margin-top: 0;
    }
    
    
    
    .serviceVendorContent{
    text-decoration: none;
    &:hover {
    text-decoration: none;
    }
    .serviceVendorText{
    color: var(--GW-FOCUS-COLOR);
    }
    }
    
    
    
    .servicePrimaryContact{
    text-decoration: none;
    color: var(--GW-FOCUS-COLOR);
    }
    }
    
    
    
    .claimPaymentsContainer{
    .paymentsGrid{
    margin-top: var(--GW-SPACING-4);
    border-bottom: var(--gw-payment-grid-border-bottom) solid var(--GW-BORDER-COLOR);
    }
    .paymentGridItem{
    align-items: flex-start;
    width: 100%;
    .paymentGridItemLabel{
    padding-top: 0;
    }
    }
    .noClaimPaymentsDetails{
    font-size: var(--GW-FONT-SIZE-BODY);
    }
    .gwInfoIcon{
    font-size: var(--GW-FONT-SIZE-BODY);
    padding: 0;
    background-color: var(--GW-BACKGROUND-COLOR);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    }
    }
    }
    
    
    
    .gwClaimDetailsNotesWrapper {
    word-break: normal;
    }
    
    
    
    .labelStyle{
    font-weight: 400;
    font-size: var(--GW-FONT-SIZE-H5);
    }

    .link{
        font-weight: 600;
    }
    
    