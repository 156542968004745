:root {
    --gw--map-label-margin-bottom: 10px;
}

.detailsContainer {
    .label-backgroundcolor {
        background-color: var(--GW-COLOR-INFO-CONTRAST);
        font-size: var(--GW-FONT-SIZE-H4);
        margin-bottom: var(--gw--map-label-margin-bottom);
    }
}
