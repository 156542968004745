.typeOfIncidentContainer {
  .glDescribeInjuriesLabel {
    --fieldLabel-font-size: var(--GW-FONT-SIZE-BODY);
    --fieldLabel-font-family: var(--GW-LABEL-FONT-FAMILY);
    --fieldLabel-font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    --fieldLabel-line-height: var(--GW-SPACING-6);
    --fieldLabel-margin-y: var(--GW-SPACING-2);
    font-size: var(--fieldLabel-font-size);
    font-family: var(--fieldLabel-font-family);
    font-weight: var(--fieldLabel-font-weight);
    line-height: var(--fieldLabel-line-height);
    margin-bottom: var(--fieldLabel-margin-y);
    display: inline-block;
    position: relative;
  }

  .glRemoveInjuredIcon {
    color: var(--GW-COLOR-SUCCESS);
  }

  .typeOfIncidentLabel {
    --field-label-max-width: 320px;
    max-width: var(--field-label-max-width);
  }
}
