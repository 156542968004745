@import "~@jutro/theme/assets/sass/helpers";

.offerLink {
    height: auto;
    width: 100%;
}

.rectangleOfferContainer {
    width: 100%;
    background-color: var(--GW-BRAND-COLOR-1);
    display: flex;
    align-items: center;

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .text {
        --gw-personalized-offer-rectangle-text-width: calc(100% - 20%);

        color: var(--GW-BACKGROUND-COLOR);
        max-width: var(--gw-personalized-offer-rectangle-text-width);
        font-size: var(--GW-FONT-SIZE-H4);
        line-height: var(--GW-LINE-HEIGHT-H1);
    }
}

.leaderBoardOfferContainer {
    height: calc(4.5 * var(--GW-SPACING-5));

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .text {
        --gw-personalized-offer-leaderboard-text-width: calc(100% - 20%);

        color: var(--GW-BACKGROUND-COLOR);
        max-width: var(--gw-personalized-offer-leaderboard-text-width);
        font-size: var(--GW-FONT-SIZE-H4);
        line-height: var(--GW-LINE-HEIGHT-H1);
    }
}