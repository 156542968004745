@import "~@jutro/theme/assets/sass/helpers";

.entryPageTemplate {
  @include gw-breakpoint-up(desktop) {
    display: flex;
    height: 100vh;
  }

  .entryPageTemplateHeader {
    --gw-header-flex: 0 0 33%;
    --gw-header-max-width: 33%;
    --gw-header-padding: 96px 24px 24px 24px;

    @include gw-breakpoint-up(desktop) {
      --gw-header-flex: 0 0 25%;
      --gw-header-max-width: 25%;
      flex: var(--gw-header-flex);
      width: var(--gw-header-max-width);
      max-width: var(--gw-header-max-width);
    }

    @include gw-breakpoint-down(tablet) {
      --gw-header-padding: 20px 42px 20px 23px;
      display: flex;
    }
    padding: var(--gw-header-padding);
    background-color: #0033ab;
   // background-color:var(--GW-BACKGROUND-COLOR);

    .entryPageTemplateLogo {

      @include gw-breakpoint-up(desktop) {
        text-align: center;
      }
      margin-bottom: var(--GW-SPACING-2);

      @include gw-breakpoint-down(tablet) {
        --gw-logo-container-width: 120px;
        width: var(--gw-logo-container-width);
      }

      img {
        
        --gw-logo-img-max-width: 200px;
        @include gw-breakpoint-down(tablet) {
          --gw-logo-img-max-width: 100px;
        }
        max-width: var(--gw-logo-img-max-width);
      }
    }

    .entryPageTemplateApplicationName {
     color: var(--GW-COLOR-INFO-CONTRAST);
      //color:var(--GW-TEXT-COLOR-1);
      font-weight: var(--GW-FONT-WEIGHT-REGULAR);
      text-align: center;
      font-size: var(--GW-FONT-SIZE-H4);
      
    }
  }

  .entryPageTemplateContent {
    --gw-content-flex: 67%;
    --gw-content-padding: 96px 24px 24px 24px;

    @include gw-breakpoint-up(desktop) {
      --gw-content-max-width: 75%;
      --gw-content-flex: 0 0 75%;
    }

    display: flex;
    justify-content: center;
    flex: var(--gw-content-flex);
    width: var(--gw-content-max-width);
    max-width: var(--gw-content-max-width);
    padding: var(--gw-content-padding);

    .entryPageContentContainer {
      width: 600px;
    }
  }
}
