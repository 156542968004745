.printButton {
    justify-self: flex-end;
}

.policyDetailContainer {
    padding: var(--GW-SPACING-3) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);

    div[class='jut__TabSet__content'] {
        margin-top: var(--GW-SPACING-6);
    }
}

.policyDetailsSection:not(:last-child) {
    margin-bottom: var(--GW-SPACING-8);
}

.idCardContainer {
    display: flex;
    justify-content: flex-end;
}

.policyBackButton {
    margin-bottom: var(--GW-SPACING-2);
    i {
        position: relative;
        top: calc(1.25 * var(--GW-SPACING-1));
    }
}

h2[class='policyDetailSectionTitle'] {
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    margin-bottom: var(--GW-SPACING-3);
}

.policyChangeButtonContainer {
    margin-top: var(--GW-SPACING-6);
}

.headerContainer {
    margin-bottom: var(--GW-SPACING-6);

    h1[class='pageTitle'] {
        margin-bottom: 0;
    }
}
