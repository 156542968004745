.policyCoveragePadding {
    padding: 0;
}

h2[class='policyCoveragePETitle'] {
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    margin-bottom: 0;
    padding-top: 15px;
}
.referInfo{
    text-align: center;
    padding-top: 15px;
}
.label1{
    text-align: left;
}
.label2{
    text-align: left;
}

.blank{
    padding-bottom: 15px;
    }

.summaryDetailsDiv{
    column-gap: 50px;
}

.summaryDetailsDivTwo{
    column-gap: 50px;
}

.coverageNameGrid{
    column-gap: 100px;
}

