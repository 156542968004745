:root {
    --gw--more-info-document-upload-margin-top: 16px;
    --gw-upload-document-grid-margin: 16px;
    --gw-document-item-border-width: 1px;
    --gw-document-item-padding: 15px;
    --gw-document-item-link-margin-bottom: 5px;
    --gw-upload-document-grid-item-width: 385px;
    --gw-upload-document-grid-item-margin: 10px;
    --gw-upload-document-grid-delete-margin-bottom: 18px;
    --gw-upload-document-grid-delete-padding: 8px;
    --gw-upload-document-grid-delete-width: 40px;
    --gw-upload-document-grid-delete-height: 40px;
    --gw-upload-document-grid-delete-icon-border-radius: 20px;
    --gw-witness-dropdown-menu-padding: 10px;
    --gw-witness-table-input-disabled-height: 37px;
    --gw-witness-table-column-required-margin-left: 8px;
    --gw-witness-upload-document-box-width: 48px;
    --gw-witness-contactsSeperator-margin:1px 0;
    --gw-involvement-injured-dropdown-width: 120px;
    --gw--map-label-margin-bottom: 10px;
}

.injuredField {
    width:125px;
}

.phoneNumberField {
    width:150px;
}
.involvementField {
    width:135px;
}

.additionalInfoContainer {
    .uploadDocumentSection {
        margin-top: var(--gw--more-info-document-upload-margin-top);
        div[class*='wrapperWithDragAndDropDisabled'] {
            background-color: var(--GW-BACKGROUND-COLOR-BODY);
        }
    }

    .upload-document-grid {
        margin: var(--gw-upload-document-grid-margin);
    }

    .upload-document-items {
        display: flex;
        flex-wrap: wrap;
    }
    .involvmentInjuredDropDown {
        width:var(--gw-involvement-injured-dropdown-width);
    }

    .upload-document-grid-item {
        border: var(--gw-document-item-border-width) solid var(--GW-BORDER-COLOR);
        padding: var(--gw-document-item-padding);
        width: var(--gw-upload-document-grid-item-width);
        margin: var(--gw-upload-document-grid-item-margin);

        .upload-img-type {
            font-size: 48px;
            color: var(--GW-FOCUS-COLOR);
        }

        .document-name-link {
            margin-bottom: var(--gw-document-item-link-margin-bottom);
            text-decoration:none;
            justify-self: left;

            .document-name {
                color: var(--GW-FOCUS-COLOR);
                font-weight: var(--GW-FONT-WEIGHT-REGULAR);
            }
        }

        .date-item {
            font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        }
    }
    .contactsSeperator {
        margin:var(--gw-witness-contactsSeperator-margin);
    }
    .addPersonMenuItems {
        background: transparent;
        font-size: var(--GW-FONT-SIZE-BODY);
        color: var(--GW-TEXT-COLOR-2);
        padding: 0;
        &:hover{
            background: transparent;
        }
    }
    .deleteSection {
        display: block;
        margin-bottom: var(--gw-upload-document-grid-delete-margin-bottom);
        text-align: center;
        border: 1px solid var(--GW-BACKGROUND-COLOR);
        padding: var(--gw-upload-document-grid-delete-padding);
        width: var(--gw-upload-document-grid-delete-width);
        height: var(--gw-upload-document-grid-delete-height);

        &:hover {
            border: var(--gw-document-item-border-width) solid var(--GW-COLOR-SUCCESS);
            border-radius: var(--gw-upload-document-grid-delete-icon-border-radius);
            background: var(--GW-BACKGROUND-COLOR-HOVER);
        }

        .deleteIcon {
            color: var(--GW-COLOR-SUCCESS);

            &:hover {
                border: none;
                color: var(--GW-COLOR-SUCCESS);
            }
        }

    }

    .deleteRow {
        margin-top: 0;
    }

    .addPersonDropDown {
        font-size: var(--GW-FONT-SIZE-BODY);

        >div {
            border: var(--gw-document-item-border-width) solid var(--GW-BACKGROUND-COLOR-BODY);
            padding: var(--gw-witness-dropdown-menu-padding) 0;
            --dropdownmenu-max-width: auto;
            max-width: var(--dropdownmenu-max-width);
        }

        a {
            font-size: var(--GW-FONT-SIZE-BODY);
            color: var(--GW-BRAND-COLOR-2-ACTIVE);

            &:hover {
                background-color: var(--GW-BACKGROUND-COLOR-BODY);
            }
        }
    }

    .addPersonContactMenuItems {
        background: transparent;
        font-size: var(--GW-FONT-SIZE-BODY);
        color: var(--GW-BRAND-COLOR-2-ACTIVE);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }


    .tableColumn{
        input[disabled] {
            height: var(--gw-witness-table-input-disabled-height);
            width:100%;
            border: solid var(--input-border-width) var(--input-border-color);
            padding: var(--input-padding-y) var(--input-padding-x);
        }
    }
    .uploadDocumentBox{
        display: flex;
        align-items: left;
        width:100%;
        justify-content: left;
        div[class*='uploadDocumentSection'] {
            width: calc(100% - var(--gw-witness-upload-document-box-width));
            text-align: left;
        }
   }
   .witnessTable {
        --gw-witness-table-padding-left-right: 1rem;
        padding: var(--table-padding-x) var(--gw-witness-table-padding-left-right);
    }
    .lossLocationContainer {
        .label-backgroundcolor {
            background-color: var(--GW-COLOR-INFO-CONTRAST);
            font-size: var(--GW-FONT-SIZE-BODY);
            margin-bottom: var(--gw--map-label-margin-bottom);
        }
        .addressLinesStyle {
            align-items: normal;
        }
        .infoLableIconClass{
            --gw-icon-top-spacing: 2px;
            margin-right: var(--GW-SPACING-1);
            margin-top: var(--gw-icon-top-spacing);
            position: absolute;
        }
        .mapInfoLableClass {
            margin-left: var(--GW-SPACING-6);
        }
    }
}
