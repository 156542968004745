:root{
	--gw-damage-picker-width: 150px;
	--gw-car-part-z-index-trunk-side: 1;
	--gw-car-part-z-index-car-part: 2;
	--gw-car-part-z-index-rear-roof: 3;
	--gw-car-part-left: 41px;
	--gw-car-part-left-corner-left: 10px;
	--gw-car-part-right-corner-left: 110px;
	--gw-car-part-hood-top: 26px;
	--gw-car-part-right-side-top: 26px;
	--gw-car-part-right-side-left: 110px;
	--gw-car-part-windshield-left: 18px;
	--gw-car-part-windshield-top: 92px;
	--gw-car-part-left-side-left: 10px;
	--gw-car-part-left-side-top: 26px;
	--gw-car-part-left-door-left: 10px;
	--gw-car-part-left-door-top: 111px;
	--gw-car-part-right-door-left: 111px;
	--gw-car-part-right-door-top: 110px;
	--gw-car-part-roof-left: 36px;
	--gw-car-part-roof-top: 144px;
	--gw-car-part-left-door-rear-left: 9px;
	--gw-car-part-left-door-rear-top: 181px;
	--gw-car-part-right-door-rear-left: 111px;
	--gw-car-part-right-door-rear-top: 181px;
	--gw-car-part-windshield-rear-left: 18px;
	--gw-car-part-windshield-rear-top: 230px;
	--gw-car-part-left-side-rear-left: 0;
	--gw-car-part-left-side-rear-top: 283px;
	--gw-car-part-right-side-rear-left: 109px;
	--gw-car-part-right-side-rear-top: 283px;
	--gw-car-part-trunk-top: 283px;
	--gw-car-part-bumper-rear-top: 305px;
	--gw-car-part-left-corner-rear-left: 0;
	--gw-car-part-left-corner-rear-top: 305px;
	--gw-car-part-right-corner-rear-left: 108px;
	--gw-car-part-right-corner-rear-top: 305px;
	--gw-damge-info-padded: 72px 30px;
	--gw-damage-list-trash-color: var(--GW-FOCUS-COLOR);
	--gw-damage-list-trash-background-color: var(--GW-COLOR-INFO-CONTRAST);
	--gw-trash-digital-button-margin: 21px;
	--gw-trash-digital-button-line-height: 1.5;
	--gw-trash-digital-button-height: 20px;
}
.gw_damage_picker {
	margin: auto;
	width: var(--gw-damage-picker-width);
	position: relative;
	text-align: center;
}
.gw_car_part {
	position: absolute;
	z-index: var(--gw-car-part-z-index-car-part);
	cursor: pointer;
	&.gw-bumper {
		left: var(--gw-car-part-left);
		top: 0;
	}
	&.gw-left-corner {
		left: var(--gw-car-part-left-corner-left);
		top: 0;
	}
	&.gw-right-corner {
		left: var(--gw-car-part-right-corner-left);
		top: 0;
	}
	&.gw-hood {
		left: var(--gw-car-part-left);
		top: var(--gw-car-part-hood-top);
	}
	&.gw-right-side {
		left: var(--gw-car-part-right-side-left);
		top: var(--gw-car-part-right-side-top);
		z-index: var(--gw-car-part-z-index-trunk-side);
	}
	&.gw-left-side {
		left: var(--gw-car-part-left-side-left);
		top: var(--gw-car-part-left-side-top);
		z-index: var(--gw-car-part-z-index-trunk-side);
	}
	&.gw-windshield {
		left: var(--gw-car-part-windshield-left);
		top: var(--gw-car-part-windshield-top);
	}
	&.gw-left-door {
		left: var(--gw-car-part-left-door-left);
		top: var(--gw-car-part-left-door-top);
	}
	&.gw-right-door {
		left: var(--gw-car-part-right-door-left);
		top: var(--gw-car-part-right-door-top);
	}
	&.gw-roof {
		left: var(--gw-car-part-roof-left);
		top: var(--gw-car-part-roof-top);
		z-index: var(--gw-car-part-z-index-rear-roof);
	}
	&.gw-left-door-rear {
		left: var(--gw-car-part-left-door-rear-left);
		top: var(--gw-car-part-left-door-rear-top);
	}
	&.gw-right-door-rear {
		left: var(--gw-car-part-right-door-rear-left);
		top: var(--gw-car-part-right-door-rear-top);
	}
	&.gw-windshield-rear {
		left: var(--gw-car-part-windshield-rear-left);
		top: var(--gw-car-part-windshield-rear-top);
	}
	&.gw-left-side-rear {
		left: var(--gw-car-part-left-side-rear-left);
		top: var(--gw-car-part-left-side-rear-top);
		z-index: var(--gw-car-part-z-index-rear-roof);
	}
	&.gw-right-side-rear {
		left: var(--gw-car-part-right-side-rear-left);
		top: var(--gw-car-part-right-side-rear-top);
		z-index: var(--gw-car-part-z-index-rear-roof);
	}
	&.gw-trunk {
		left: var(--gw-car-part-left);
		top: var( --gw-car-part-trunk-top);
		z-index: var(--gw-car-part-z-index-trunk-side);
	}
	&.gw-bumper-rear {
		left: var(--gw-car-part-left);
		top: var(--gw-car-part-bumper-rear-top);
	}
	&.gw-left-corner-rear {
		left: var(--gw-car-part-left-corner-rear-left);
		top: var(--gw-car-part-left-corner-rear-top);
	}
	&.gw-right-corner-rear {
		left: var(--gw-car-part-right-corner-rear-left);
		top: var(--gw-car-part-right-corner-rear-top);
	}
}
.gw_vehicle_collision{
    display: inline-block;
}
.gw_car {
    display: inline-block;
}
.gw_vehicle_collision {
    display: inline-block;
}
.gw_damage_info {
    display: inline-block;
}
.gw_damge_info_padded {
    padding: var(--gw-damge-info-padded);
}
.gw_trash_digital_button {
	min-width: 0px;
    padding: 0px;
    margin: var(--gw-trash-digital-button-margin);
    line-height: var(--gw-trash-digital-button-line-height);
	height: var(--gw-trash-digital-button-height);
	cursor: pointer;
    color:var(--gw-damage-list-trash-color);
}
.gw_trash_digital_button:hover {
	background-color: var(--gw-damage-list-trash-background-color);
	color:var(--gw-damage-list-trash-color);
}

.gwCarContainer {
	display: grid;
    grid-template-columns: 0.4fr 1fr;
}