.signUpContainer {

  > * {
      margin-bottom: var(--GW-SPACING-6);
  }

  .signInLinkContainer {
    border-top: 1px solid var(--GW-BORDER-COLOR);
    padding-top: var(--GW-SPACING-6);
  }

  .heading {
    font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    font-size: var(--GW-FONT-SIZE-H2);
    margin-bottom: 2%;
    text-align: left;
  }

  .subheading{
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
    font-size: var(--GW-FONT-SIZE-H5);
  }

  .subsubheading{
    font-weight: 700;
    padding-top: var(--GW-SPACING-3);
    padding-bottom: var(--GW-SPACING-6);
    font-size: 14px
  }

  .passwordinfo{
    visibility: hidden;
    display: none;
  }

  .policyDetailsContainerArea{
    position: relative;
  }

  .zipCodeArea, .mobileNumberArea, .policyNumberArea, .passwordArea {
    position: relative;
    padding-top: 12px;
    span{
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 15px;
      a{
        position: relative;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
      span{
        width: 110%;
        position: relative;
        top:-42px;
        font-style: italic;
        color: #888;
        float:left;
      }
    }
    label{
      span{
        position: relative;
        margin-left:0px;
        top: 0px;
        font-style: normal;
        color: #222;
        a{
          font-style: normal;
        }
      }
    }
  }

  .mobileNumberArea, .policyNumberArea, .zipCodeArea, .passwordArea {
    span{
      z-index: 1;
      a:after{
        font-family: "Material Icons"; 
        content: "\e887";
      }
    }
    label{
      position: relative;
    }
  }

  .policyNumberArea{
    span{
      a{
        left: 107px;
      }
      span{
        left: 110px;
      }
    }
  }

  .mobileNumberArea{
    span{
      a{
        left: 110px;
      }
      span{
        left: 115px;
      }
    }
  }

  .zipCodeArea{
    span{
      a{
        left: 65px;
      }
      span{
        left: 70px;
      }
    }
  }
  .passwordArea{
    span{
      a{
        left: 75px;
      }
      span{
        left: 80px;
      }
    }
  }
}

.heading {
  font-weight: var(--GW-FONT-WEIGHT-REGULAR);
  font-size: var(--GW-FONT-SIZE-H2);
  margin-bottom: 2%;
  text-align: left;
}

.otpButton {
  margin-top:20%
}

.actionsContainer {
  margin-top: 5%
}
@media only screen and (max-width: 400px) {
  .policyNumberArea {
    span{
      a{
        left: -70px !important
      }
      span{
        width: 50% !important;
        font-size: 14px !important;
        top:-15px !important
      }
    }
  }
  .zipCodeArea{
    margin-top: 10px;
  }
  .passwordArea{
    margin-top: 20px;
    span{
      span{
        left: 0px !important;
        width: 100% !important;
        top:-56px !important;
        font-size: 14px !important;
        line-height: 16px !important;
      }
    }
  }
  .mobileNumberArea, .zipCodeArea {
    margin-top:15px;
    span{
      span{
        width: 105% !important;
        left: 0px !important;
        top:-46px !important;
        font-size: 14px !important;
      }
    }
  }
}