.injuredField {
    width:125px;
}

.phoneNumberField {
    width:150px;
}

.involvementField {
    width:160px;
}