.accountSubHeaderContainer {
    // margin-bottom: var(--GW-SPACING-6);
    // padding-top: var(--GW-SPACING-6);
    // border-top: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    position: relative;
    width: 200%;
}
@media screen and (max-width: 768px){
    .accountSubHeaderContainer {
        width: 100%;
    }
    .subHeadRightContainer{
        display:none;
        visibility: hidden;
    }
}
.emailErrorText{
    text-align: center;
    color: var(--GW-COLOR-ERROR);
    position: relative;
    top:-12px;
    left: -25px;
    opacity: 0.6;
    line-height: 12px;
    height: 12px;
}
.FieldLabel.requiredSymbol , .HomeNumberField2fa span{color: var(--GW-COLOR-ERROR) !important}