.tabContent {
    display: flex;
    align-items: center;

    > i {
        padding-right: 2px;
    }
}

.billingSummaryContainer{
    padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);
    margin-top: var(--GW-SPACING-4);
}
