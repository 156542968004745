:root { 
    --gw-header-page-bottom-line: 2px;
}

.gwPageHeader {
    border-bottom: var(--gw-header-page-bottom-line) solid;
}   

.gwPageHeaderRightPanel {
    width: 100%;
}

.gwRowLayout {
    margin-bottom: var(--GW-SPACING-2);
}

.iconMargin {
    margin-top: var(--GW-SPACING-5);
}

.gwDisplayNoMessage {
    font-weight: normal;
    margin-top: var(--GW-SPACING-2);
    margin-left: var(--GW-SPACING-2);
}

.gwClaimsLinkContent {
    text-decoration: none;
}

.claimsContainer {
    padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);
}
