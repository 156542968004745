.accountSummaryContainer {
    padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);
}

.accountSectionReadonly {
    --gw-user-section-readonly-opacity: 0.5;
    opacity: var(--gw-user-section-readonly-opacity);
    @extend .accountSummaryContainer;
}

.accountHeaderContainer {
    margin-bottom: var(--GW-SPACING-6);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

.infoField {
    color:#c4221f !important;
    font-size: var(--GW-FONT-SIZE-H4);
}    