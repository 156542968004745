
.wcInjuryContainer {
    .wcAdditionalInjuriesLabel {
        --fieldLabel-font-size: var(--GW-FONT-SIZE-BODY);
        --fieldLabel-font-family: var(--GW-LABEL-FONT-FAMILY);
        --fieldLabel-font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        --fieldLabel-line-height: var(--GW-LINE-HEIGHT-BODY);
        --fieldLabel-margin-y: var(--GW-SPACING-2);
        font-size: var(--fieldLabel-font-size);
        font-family: var(--fieldLabel-font-family);
        font-weight: var(--fieldLabel-font-weight);
        line-height: var(--fieldLabel-line-height);
        margin-bottom: var(--fieldLabel-margin-y);
        display: inline-block;
        position: relative;
    }

    .wcInjuryDetail {
        --wc-injury-detail-margin-left: 2rem;
        margin-left: var(--wc-injury-detail-margin-left);
    }

    .wcAddInjuryDeleteIcon {
        display: flex;
    }
}
