.loginOtpContainer {
    .heading {
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        font-size: var(--GW-FONT-SIZE-H2);
        margin-bottom: var(--GW-SPACING-6);
    }    
}
.accountHolderName{
  margin-top: 5% !important;
  margin-bottom: 1% !important;
} 
.otpButton{
  margin-top:5%
}

.sigInActionContainer{
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.dontAskAgainStyle{
  div:first-child{
    margin-left:32px;
    z-index: 0;
  }
  div:nth-child(2){
    position: relative;
    top:-30px;
    left:-30px;
    z-index: 1;
  }
}





