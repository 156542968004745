.errorPage {
    display: flex;
    flex-direction: column;

    .heading {
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        font-size: var(--GW-FONT-SIZE-H3);
        margin-bottom: var(--GW-SPACING-5);
    }
}
