@import "~@jutro/theme/assets/sass/helpers";

:root{
  --vendor-information-margin-top: 7px;
  --vendor-information-max-width: 230px;
}

.addressList {
  --gw-address-selected-color: var(--GW-FOCUS-COLOR);
  --gw-max-height: 100%;
  --gw-max-width: 100%;
  --gw-margin-zero: 0;
  --gw-padding-zero: 0;
  --gw-listcontent-border-color: var(--GW-BORDER-COLOR);
  --gw-listcontent-padding: var(--GW-SPACING-4) 0;
  --gw-content-border-color: var(--GW-BORDER-COLOR);
  --gw-secondary-detail-color: var(--GW-TEXT-COLOR-2);
  --gw-listcontent-hover-background: var(--GW-BACKGROUND-COLOR-ELEMENT-HOVER);
  --gw-list-padding-left: var(--GW-SPACING-4);
  --gw-check-icon-margin-right: calc(1.5 * var(--GW-SPACING-1));
  --gw-icon-padding: var(--GW-SPACING-3);


  @include gw-breakpoint-up(tablet) {
    flex: 1;
  }

  height: var(--gw-max-height);
  overflow-y: auto;

  .addressListContainer {
    list-style: none;
    padding: var(--gw-padding-zero);
    height: var(--gw-max-height);
    margin: var(--gw-margin-zero);
  }
  .listIcon {
    color: var(--GW-BACKGROUND-COLOR);
    margin-right: var(--gw-check-icon-margin-right);
  }
  .checkIcon {
    display: none;
  }
  .selected {
    background-color: var(--gw-address-selected-color);
    .checkIcon {
      display: block;
    }
  }
  .angleIcon {
    color: var(--GW-TEXT-COLOR-2);
  }
  .addressListContent {
    display: flex;
    align-items: center;
    padding: var(--gw-listcontent-padding);
    border-bottom: 1px solid var(--gw-listcontent-border-color);

    &:hover {
      background-color: var(--gw-listcontent-hover-background);
    }
    .lists {
      padding-left: var(--gw-list-padding-left);
      width: var(--gw-max-width);
      .listItem {
        .primaryDetail {
          color: var(--GW-COLOR-WARNING-CONTRAST);
          margin: var(--gw-margin-zero);
        }
        .secondaryDetail {
          color: var(--gw-secondary-detail-color);
          margin: var(--gw-margin-zero);
          display: flex;
          justify-content: space-between;
        }
        .gwShowForPhoneLandscapeDown {
          display: none;
        }
      }
    }
  }
}
.selected .addressListContent {
  &:hover {
    background: var(--GW-BACKGROUND-COLOR-ELEMENT-HOVER);
  }
}

.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--GW-TEXT-COLOR-2);
  align-items: center;
  height: var(--gw-max-height);

  .icon {
    font-size: var(--GW-FONT-SIZE-H4);
    color: var(--GW-BORDER-COLOR);
    padding: var(--gw-icon-padding);
  }

  .desc {
    font-size: var(--GW-FONT-SIZE-SM);
    margin: var(--gw-margin-zero);
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--gw-max-height);
}

.marker {
  --gw-map-marker-radius: 20px;
  --gw-map-marker-padding-top: var(--GW-SPACING-1);

  cursor: pointer;
  position: absolute;
  width: var(--gw-map-marker-radius);
  height: var(--gw-map-marker-radius);
  border-radius: var(--gw-map-marker-radius);
  box-shadow: 0 0 3px 1px rgba(var(--GW-COLOR-WARNING-CONTRAST), 0.5);
  text-align: center;
  padding-top: var(--gw-map-marker-padding-top);
  transform: translateX(-var(--gw-map-marker-radius)) translateY(-var(--gw-map-marker-radius));
}

.icon {
  color: var(--GW-COLOR-INFO-CONTRAST);
  font-size: var(--GW-FONT-SIZE-SUBTEXT)
}
.container {
  height: var(--gw-max-height);
  width: var(--gw-max-width);
}

.vendorName {
  font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
}

.vendorInformation {
  color: var(--GW-BORDER-COLOR);
  margin-top: var(--vendor-information-margin-top);
  max-width: var(--vendor-information-max-width);
}
