
.fileUpload {
    margin-bottom: var(--GW-SPACING-6);
}

.policyDocumentsTableNoPadding {
    padding: 0;
}

.fileTypeIcon {
    position: relative;
    top: var(--GW-SPACING-1);
}