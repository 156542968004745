.dateRangeContainer {
  display: flex;
}

.startDateValue {
  margin-right: var(--GW-SPACING-1);
  text-align: right;
  width: auto;
  padding-bottom: 0;
}

.endDateValue {
  margin-left: var(--GW-SPACING-1);
  text-align: left;
  width: auto;
  padding-bottom: 0;
}
