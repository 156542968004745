@import "~@jutro/theme/assets/sass/helpers";

.gwMediaSidebarTextContainer {
  background-color: var(--GW-COLOR-INFO-CONTRAST);
  margin-bottom: var(--GW-SPACING-2);
  padding: var(--GW-SPACING-1);

  @include gw-breakpoint-down(tablet) {
    display:none;
  }
}
