@import "~@jutro/theme/assets/sass/helpers";
.grayBackground {
    background-color: var(--GW-BACKGROUND-COLOR-BODY);
    border-radius: var(--GW-BORDER-WIDTH);
    padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);
}

.totalPremiumContainer {
    div[class^='jut__FieldComponent__fieldComponent'] {
        div[class^='jut__FieldComponent__contentContainer'] {
            text-align: right;
        }
    }
}

.policyCommonDetailsContainer {
    margin-top: var(--GW-SPACING-6);

    @include gw-breakpoint-down(tablet) {
        div[class*='fieldComponent']{
            display: flex;
        }
        div[class*='fieldLabelContainer']{
            flex: 2;
        }
        div[class*='contentContainer'] {
            text-align: right;
        }
    }
}

.producerlinks {
    text-align: left;
    @include gw-breakpoint-down(tablet) {
        text-align: left;
    }
    @include gw-breakpoint-down(phone) {
        text-align: left;
    }
}

.premiumSeperator {
    border-bottom: solid var(--GW-BORDER-WIDTH) var(--GW-BORDER-COLOR)
}

h2[class='policyAgentTitle'] {
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    margin-bottom: 16px;
    padding-top: 15px;
}
