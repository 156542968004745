@import "~@jutro/theme/assets/sass/helpers";
.pageContainer {
	margin-top: var(--GW-SPACING-8);
}
.policySectionStyle {
	padding-bottom: var(--GW-SPACING-5);
	border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
	margin-bottom: var(--GW-SPACING-10);
}
.coverageTitle {
	--gw-policy-section-padding: var(--GW-SPACING-4);
	padding: var(--gw-policy-section-padding);
}
.vehicleCoverageContainer {
	--gw-vehicle-coverage-container-width: 50%;
	@include gw-breakpoint-down(tablet) {
		--gw-vehicle-coverage-container-width: 100%;
	}
	width: var(--gw-vehicle-coverage-container-width);
}
.coverageDetailsContainer {
	margin-top: var(--GW-SPACING-4);
	background-color: var(--GW-COLOR-INFO-CONTRAST);
}


.toggleLocationInfo {
    text-decoration: none;
    text-align: right;
    justify-content: right;
    color: var(--GW-TEXT-COLOR-1);

    &:hover {
		color: var(--GW-TEXT-COLOR-1-HOVER);
		text-decoration: none;
    }
}

.vehicleLicencePlate {
    --gw-vehicle-licence-plate-flex-value: 108px;
    div:first-child{
        flex: 0 1 var(--gw-vehicle-licence-plate-flex-value);
    }
}
