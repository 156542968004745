@import "~@jutro/theme/assets/sass/helpers";

.caClaimVehicle {
    .claimsCAContainerPage {
        @include gw-breakpoint-down(tablet) {
            --ca-claim-container-width: 20px;
            width:var(--ca-claim-container-width);
        }
    }

    .caClaimAddVehicle {
        --pa-claim-add-vehicle-width: 14rem;
        --pa-claim-add-vehicle-margin-top-bottom: 4rem;
        width: var(--pa-claim-add-vehicle-width);
        margin: var(--pa-claim-add-vehicle-margin-top-bottom) 0;
    }

    .caPointOfImpactLabel {
        --fieldLabel-font-size: var(--GW-FONT-SIZE-BODY);
        --fieldLabel-font-family: var(--GW-LABEL-FONT-FAMILY);
        --fieldLabel-font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        --fieldLabel-line-height: var(--GW-LINE-HEIGHT-BODY);
        --fieldLabel-margin-y: var(--GW-SPACING-2);
        font-size: var(--fieldLabel-font-size);
        font-family: var(--fieldLabel-font-family);
        font-weight: var(--fieldLabel-font-weight);
        line-height: var(--fieldLabel-line-height);
        margin-bottom: var(--fieldLabel-margin-y);
        display: inline-block;
        position: relative;
    }

    .caPointOfImpactSelect {
        --point-of-impact-border-color: var(--GW-TEXT-COLOR-1);
        --point-of-impact-border-size: 1px;
        border: var(--point-of-impact-border-size) solid var(--point-of-impact-border-color);
    }

    .addPersonDropDown {
        --pa-claim-passenger-dropdown-menu-border-width: 1px;
        --pa-claim-passenger-dropdown-menu-padding: 10px;

        font-size: var(--GW-FONT-SIZE-BODY);
        > div {
            border: var(--pa-claim-passenger-dropdown-menu-border-width) solid var(--GW-BACKGROUND-COLOR-BODY);
            padding: var(--pa-claim-passenger-dropdown-menu-padding) 0;
        }
        a {
            font-size: var(--GW-FONT-SIZE-BODY);
            color: var(--GW-TEXT-COLOR-2);
            &:hover{
                background-color: var(--GW-BACKGROUND-COLOR-BODY);
            }
        }
    }

    .chevronStyle {
        --gw-chevron-padding-top-bottom: 15px;
        padding: var(--gw-chevron-padding-top-bottom) 0;
    }

    .accordionTitle {
        --gw-payment-schedule-toggle-margin-left: 30px;
        --gw-payment-schedule-toggle-margin-top: 8px;
        font-weight: var(--GW-FONT-WEIGHT-LIGHT);
        background-color: transparent;
        color: var(--GW-TEXT-COLOR-2);
        cursor: pointer;
        margin-left: var(--gw-payment-schedule-toggle-margin-left);
        margin-top: var(--gw-payment-schedule-toggle-margin-top);
    }

    .addPersonMenuItems {
        background-color: transparent;
        font-size: var(--GW-FONT-SIZE-BODY);
        color: var(--GW-TEXT-COLOR-2);
        padding: 0;
        &:hover{
            background: transparent;
        }
    }

    .addPersonMenuSeparator {
        margin: 0;
    }

    .addPersonDeleteSection {
        display: block;
        margin-top: var(--gw-upload-document-grid-delete-margin-top);
        text-align: center;
        border: 1px solid var(--GW-BACKGROUND-COLOR);
        padding: var(--gw-upload-document-grid-delete-padding);
        width: var(--gw-upload-document-grid-delete-width);
        height: var(--gw-upload-document-grid-delete-height);

        &:hover {
            border: var(--gw-document-item-border-width) solid var(--GW-COLOR-SUCCESS);
            border-radius: var(--gw-upload-document-grid-delete-icon-border-radius);
            background: var(--GW-BACKGROUND-COLOR-HOVER);
        }

        .addPersonRemoveIcon {
            color: var(--GW-COLOR-SUCCESS);

            &:hover {
                border: none;
                color: var(--GW-COLOR-SUCCESS);
            }
        }
    }

    .deleteRow {
        margin-top: 0;
    }

    .caVehicleIncidentDeleteIcon {
        --delete-icon-margin-right: 1em;
        color: var(--GW-COLOR-SUCCESS);
        float: right;
        margin-right: var(--delete-icon-margin-right);
        padding: 0;
    }

    .vehicleAccordion {
        --accordion-card-body-margin-x: 1rem;
    }

    .caAddPassengerTableContainer:empty {
        padding-bottom: var(--GW-SPACING-6);
    }
}
