@import "~@jutro/theme/assets/sass/helpers";

.googleMapContainer {
  --gw-map-container-height: 560px;
  --gw-map-height: 100%;
  --gw-full-width: 100%;
  --gw-search-options: 5px 0px;
  --gw-search-margin-left:  calc(2.5 * var(--GW-SPACING-1));
  --gw-search-policy-width: 20px;
  --gw-search-policy-height: 20px;
  --gw-label-padding: 5px;
  --gw-border-radius: calc(2.5 * var(--GW-SPACING-1));
  --search-margin-btn: 15px;
  --search-height: 70px;
  --search-border-radius: 5px;

  display: flex;
  align-items: stretch;

  @include gw-breakpoint-up(tablet) {
    height: var(--gw-map-container-height);
  }

  @include gw-breakpoint-down(tablet) {
    flex-direction: column;
  }

  .googleMap {
    @include gw-breakpoint-up(tablet) {
      flex: 2;
    }
    height: var(--gw-map-height);
    position: relative;
  }
  .mapArea {
    width: var(--gw-full-width);
    height: var(--gw-map-height);
  }
  .searchOptionsContainer {
    padding: var(--gw-search-options);
    display: block;
    .mapOptions {
      display: inline-flex;
      cursor: pointer;
      margin-left: var(--gw-search-margin-left);
      .policy {
        background-color: var(--GW-COLOR-ERROR);
      }
      .incident {
        background-color: var(--GW-COLOR-ERROR);
        display: flex;
      }
      .incident, .policy {
        justify-content: center;
        align-items: center;
        width: var(--gw-search-policy-width);
        height: var(--gw-search-policy-height);
        border-radius: var(--gw-border-radius);
      }
      .label {
        padding-left: var(--gw-label-padding);
        color: var(--GW-LINK-COLOR);
      }
      i {
        color: var(--GW-BACKGROUND-COLOR-BODY)
      }
    }
  }

  .closestRepair {
    select {
      width: var(--gw-full-width);
    }
  }
  .searchButton {
    margin-bottom: var(--search-margin-btn);
    height: var(--search-height);
    width: var(--gw-full-width);
    border: none;
    border-radius: var(--search-border-radius);
    color: var(--GW-BACKGROUND-COLOR);
    font-size: var(--GW-FONT-SIZE-H3);
    background-image: linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0, 0.5)), url('../images/search_map_button.png');
  }
}
