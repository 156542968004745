@import "~@jutro/theme/assets/sass/helpers";

.repairFacilitySearch {
  --gw-height: 100%;

  display: flex;
  flex-direction: column;
  height: var(--gw-height);
  position: relative;
  width: 100%;

  .mobileHeader {
    align-items: center;
    background-color: var(--GW-BRAND-COLOR-2);
    color: var(--GW-TEXT-COLOR-3);
    display: flex;
    font-size: var(--GW-FONT-SIZE-H4);
    padding: calc(2.5 * var(--GW-SPACING-1));

    .back {
      align-items: center;
      display: flex;
      height: var(--gw-height);
    }

    .title {
      margin: auto;
    }
  }
  .googleMap {
    flex: auto;
    .mapContainer {
      height: var(--gw-height);
    }
  }

  .repairFacilityDetails {
    padding: var(--GW-SPACING-4);
    align-content: flex-end;
    .container {
      display: flex;
      flex-direction: column;

      .detail {
        --padding-detail: 3px 0;
        padding: var(--padding-detail);

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }

      .primaryDetail {
        color: var(--GW-TEXT-COLOR-1);
        margin-bottom: 0;
      }

      .secondaryDetail {
        color: var(--GW-TEXT-COLOR-2);
        margin-bottom: 0;
      }

      .repairButton {
        align-self: flex-end;
      }
    }
  }
}
.fullScreen {
  min-width: 100vw;
  min-height: 100vh;
  height: 100vh;
  left: 0;
  top: 0;
}
