.linkSpace {
    padding: var(--GW-SPACING-6);
}
.billingInvoicesContainer {
    overflow: auto;
}

.gwBillingSummaryContainer {
    width: auto;
}
.tileContainer{
    text-align: left;
    margin-right: 2px;
    margin-bottom: 2px;
     flex-grow: 1;
    flex-shrink: 1;
    align-items: flex-start;
    justify-content: center;
}

.heading{
    margin-left: 60px;
}
.blank{
    padding-bottom: 8px;  
}
.headingPlan{
    margin-left: 100px;
}

h2[class='billingSummaryInfoTitle'] {
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    margin-bottom: 0;
}

.billingTablePadding {
    padding: 0;
}
