.signUpOtpContainer {

  > * {
      margin-bottom: var(--GW-SPACING-6);
  }
  .heading {
    font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    font-size: var(--GW-FONT-SIZE-H2);
    margin-bottom: 2%;
    text-align: center;
  }

}

.heading {
  font-weight: var(--GW-FONT-WEIGHT-REGULAR);
  font-size: var(--GW-FONT-SIZE-H2);
  margin-bottom: 2%;
  text-align: center;
}

.otpButton {
  margin-top:22%
}

.nextButton {
  margin-top:10%
}

.typeOfForgot {
  display: flex !important;
  justify-content: space-evenly !important;
}

.actionContainer {
  display: flex;
  justify-content: space-between;;
  margin-top: 5%;
}


