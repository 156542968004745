@import "~@jutro/theme/assets/sass/helpers";

:root {
    --gw-btn-edit: 9%;
    --gw-btn-edit-margin-left: 5px;
    --gw-btn-container-right: 12%;
}

.btnEdit {
    margin-left: var(--gw-btn-edit-margin-left);    
}

.adjusterSubject {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
}

.adjusterBtnContainer {
    position: absolute;
    right: var(--gw-btn-container-right);
}