:root {
--gw-info-message-margin-bottom: 30px;
--gw-info-message-padding: 20px;
--gw-info-message-border-radius: 2px;
--gw-info-message-min-height: 38px;
--gw-info-message-border: 1px solid transparent;
}

.infoMessage {
    background-color: var(--GW-HIGHLIGHT-COLOR-2);
    border-color: var(--GW-FOCUS-COLOR);
    margin-bottom: var(--gw-info-message-margin-bottom);
    padding: var(--gw-info-message-padding);
    border: var(--gw-info-message-border);
    border-radius: var(--gw-info-message-border-radius);
    min-height: var(--gw-info-message-min-height);
    font-size: var(--GW-FONT-SIZE-H4);
    color: var(--GW-COLOR-WARNING-CONTRAST)
}

.policyTableContainer {
    tr[class*='jut__Table__row'] {
        &:hover {
            background-color: var(--GW-BACKGROUND-COLOR-BODY);
        }
    }
}

.fnolWizardTitle {
    h2[class*='wizardTitle'] {
        margin-bottom: 0;
    }
}
