@import "~@jutro/theme/assets/sass/helpers";
.mapSearch {
  --gw-map-search-top: calc(2.5 * var(--GW-SPACING-1));
  --gw-map-search-left: calc(2.5 * var(--GW-SPACING-1));
  --gw-map-max-width: 100%;
  --gw-map-search-width: 65%;
  --gw-map-search-min-width: 235px;
  --gw-search-box-color: var(--GW-BORDER-COLOR);
  --gw-search-box-padding: 0.7em 0.6em;
  --gw-search-policy-color: var(--GW-COLOR-INFO);
  --gw-search-incident-color: var(--GW-COLOR-ERROR);
  --gw-search-label-padding: var(--GW-SPACING-5);
  --gw-search-box-padding-right: calc(2.5 * var(--GW-SPACING-1));
  --gw-dropdown-place-padding: calc(2.5 * var(--GW-SPACING-1));
  --gw-dropdown-place-min-height: calc(2 * var(--GW-SPACING-5));
  --gw-dropdown-policy-width: var(--GW-SPACING-5);
  --gw-dropdown-policy-height: var(--GW-SPACING-5);
  --gw-dropdown-policy-: var(--GW-TEXT-COLOR-2);
  --gw-search-box-icon-top: 50%;
  --gw-search-box-icon-right: 5px;
  --gw-search-box-font-size: var(--GW-FONT-SIZE-SM);

  position: absolute;
  top: var(--gw-map-search-left);
  left: var(--gw-map-search-left);
  background-color: var(--GW-COLOR-INFO-CONTRAST);
  min-width: var(--gw-map-search-min-width);
  width: var(--gw-map-search-width);

  .content {
    .toggle {
      position: relative;
      .searchBoxContainer {
        padding-bottom: 0;
      }
      .searchBox {
        padding-right: calc(1.3em + var(--gw-search-box-padding-right));
        cursor: pointer;
        border-radius: 0;
        border: none;
        box-shadow: none;
        border-bottom: 1px solid var(--gw-search-box-color);
        padding: var(--gw-search-box-padding);
        width: var(--gw-map-max-width);
      }
      .icon {
        position: absolute;
        right: var(--gw-search-box-icon-right);
        font-size: var(--gw-search-box-font-size);
        color: var(--gw-search-box-color);
        top: var(--gw-search-box-icon-top);
        transform: translateY(-50%);
      }
    }
  }
  .dropdown {
    width: var(--gw-map-max-width);
    .placeList {
      display: inline-block;
      width: var(--gw-map-max-width);
      .place {
        cursor: pointer;
        display: inline-block;
        padding: var(--gw-dropdown-place-padding);
        width: var(--gw-map-max-width);
        height: auto;
        min-height: var(--gw-dropdown-place-min-height);
        border-bottom: 1px solid var(--gw-search-box-color);
        .option {
          display: inline-flex;
          .policy,
          .incident,
          .mylocation {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 20px;
            width: var(--gw-dropdown-policy-width);
            height: var(--gw-dropdown-policy-height);
            border-radius: calc(2.5 * var(--GW-SPACING-1));
            position: relative;
            color: var(--gw-dropdown-policy-color);
          }
          .policy {
            background-color: var(--gw-search-policy-color);
          }
          .incident {
            background-color: var(--gw-search-incident-color);
          }
          .mylocation {
            background-color: var(--GW-LINK-COLOR);
          }
          .label {
            padding-left: var(--gw-search-label-padding);
          }
        }
      }
    }
  }
}
