:root {
    --gw--map-label-margin-bottom: 10px;
}

.whereContainer {
    .label-backgroundcolor {
        background-color: var(--GW-BACKGROUND-COLOR);
        font-size: var(--GW-FONT-SIZE-BODY);
        margin-bottom: var(--gw--map-label-margin-bottom);
    }
}
