.FNOLCPWizardContainer {
  div[class*="wizardMain"] {
    --gw-wizard-page-main-width: 100%;
  }
}

.cpBuildingClass {
  --gw-cp-building-background-color: var(--GW-COLOR-INFO-CONTRAST);
  font-size: var(--GW-FONT-SIZE-BODY);
  font-weight: var(--GW-FONT-WEIGHT-REGULAR);
  background-color: var(--gw-cp-building-background-color);
}

.cpDetailAddressClass {
  --gw-cp-building-background-color: var(--GW-COLOR-INFO-CONTRAST);
  font-size: var(--GW-FONT-SIZE-BODY);
  font-weight: var(--GW-FONT-WEIGHT-REGULAR);
}
