.lobValuesClass {
    padding-left: var(--GW-SPACING-3);
}

.iconCheckedClass {
    display: inline-block;
}

.iconCheckedClass-selected {
    margin-right: var(--GW-SPACING-1);
}

.itemNameClass {
    display: inline-block;
    padding-left: var(--GW-SPACING-5);
}

.itemNameSelected {
    display: inline-block;
    margin-left: var(--GW-SPACING-1);
}
