.gwContactModule {
  display: flex;
  flex-direction: row;
  background-color: var(--GW-BACKGROUND-COLOR);
  padding: calc(var(--GW-SPACING-1) + 2px) 0 calc(var(--GW-SPACING-1) + 2px) calc(var(--GW-SPACING-1) + 2px);
  margin-bottom: calc(2.5 * var(--GW-SPACING-1));

  .gwContactInformationContainer {
    width: 100%;
    line-height: normal;
    font-size: var(--GW-FONT-SIZE-H5);
    color: var(--GW-TEXT-COLOR-2);
    word-break: break-word;
  }

  .gwImageContainer {
    --gw-image-container-width: 64px;
    --gw-image-container-height: 100%;

    width: var(--gw-image-container-width);
    height: var(--gw-image-container-height);

    img {
      max-width: fit-content;
    }
  }

  .gwDisplayName {
    color: var(--GW-TEXT-COLOR-1-HOVER);
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
    font-size: var(--GW-FONT-SIZE-H4);
  }

  .gwEmail {
    cursor: pointer;
    color: var(--GW-FOCUS-COLOR);
  }
}
