
.gwAuthComponent {
    --gw-login-component-container-width: 480px;
    --gw-login-single-button-wrapper-margin: 6px 0 45px;

    flex-grow: 1;
    max-width: var(--gw-login-component-container-width);

    .heading {
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        font-size: var(--GW-FONT-SIZE-H2);
        margin-bottom: var(--GW-SPACING-5);
    }

    .forgotPasswordHeading {
        font-size: var(--GW-FONT-SIZE-H2);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        margin-bottom: var(--GW-SPACING-3);
    }

    .forgotPasswordErrorMsg {
        color: var(--GW-COLOR-ERROR);
        font-size: var(--GW-FONT-SIZE-BODY);
        margin-bottom: var(--GW-SPACING-3);
    }

    .forgotPasswordError {
        margin-bottom: var(--GW-SPACING-3);
        margin-top: var(--GW-SPACING-3);
    }

    .actionsWrapperSingleButton {
        flex-direction: row;
        display: flex;
        align-items: center;
        margin: var(--gw-login-single-button-wrapper-margin);
    }

    .coupleButtonStyle {
        justify-content: space-between;
    }

    .singleButtonStyle {
        justify-content: flex-end;
    }

    .errorMsg {
        position: relative;
        width:150%;
        color: var(--GW-COLOR-ERROR);
        font-weight: var(--GW-FONT-WEIGHT-BOLD);
    }

    .resetButton {
        margin-top:5%
    } 

}

.successDescriptionStyle {
    margin-bottom: 30px;
}
.successInfoStyle {
    font-size: 1.5rem;
    margin-bottom: 30px;
}
.successCheckStyle {
    margin-bottom: 30px;
    color: green;
}


@media only screen and (max-width: 480px) {
    .gwAuthComponent {
        .errorMsg {
            width:100%;
        }
    }
}