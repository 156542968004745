:root {
	--gw-loader-wrapper-large-min-height: 4.4em;
	--gw-loader-wrapper-large-min-width: 1.2em;
}

.claimCoverageStyle {
	margin: 0 auto var(--GW-SPACING-1) 0px;
}

.gwInlineBackNav {
	margin-bottom: var(--GW-SPACING-6);
	.policyTitle {
		margin-bottom: 0;
	}
	.gwBackToClaim {
		justify-self: start;
	}
}

.policySectionStyle {
	padding-bottom: var(--GW-SPACING-4);
	border-bottom: 2px solid var(--GW-TEXT-COLOR-2);
	margin-bottom: var(--GW-SPACING-8);
}

.gwInlineBackNav {
	position: relative;
}

.backToHomeImage {
	vertical-align: top;
	max-width: 100%;
	height: auto;
	vertical-align: middle;
	border: 0;
}

.vehicleSectionTitle {
	border-bottom: 2px solid var(--GW-TEXT-COLOR-2);
	padding: var(--GW-NEXT-SPACING-12) 0 var(--GW-NEXT-SPACING-6) 0;
}
