.policyLinkContainer {
    padding-bottom: 0;
    text-decoration: none;
}

.currencyContainer {
    div[class*='fieldComponent'] {
        padding-bottom: 0;
    }
}
