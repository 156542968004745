.policyCoveragePadding {
    padding: 0;
}

h2[class='policyCoveragePETitle'] {
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    margin-bottom: 0;
    padding-top: 15px;
}
.referInfo{
    text-align: center;
    padding-top: 15px;
}
.label1{
    text-align: left;
}
.label2{
    text-align: left;
}

